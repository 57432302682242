import React from "react";
import Container from "../components/container";
import Intro from "../components/intro";
import PageBody from "../components/page-body"
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Footer from "../components/footer";

export default function Contact({ pageContext, data: { site, main, contact } }) {

  const pc = pageContext

    return (
        <Container>
            <HelmetDatoCms seo={main.seo} favicon={site.favicon} />
            <Intro pageContext={pc} />
            <div className="md:grid md:grid-cols-4 md:gap-4">
                <div className="col-span-3">
                    <PageBody body={contact.left}></PageBody>
                </div>
                <PageBody body={contact.right}></PageBody>
            </div>
            <Footer />
        </Container>
    );
}

export const query = graphql`
  query page($locale: String!){
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    main: datoCmsMain {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    contact: datoCmsContact(locale: {eq: $locale}) {
        left,
        right
    }
  }
`;
